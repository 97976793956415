.borrowedItem {
    .modal-dialog {
        .modal-content {
            border-radius: 20px;
            background: #FFFFFF;
            box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25);

            .modal-header {
                border: none;
                padding: 1.5rem 1.5rem 0 1.5rem;

                .modal-title {
                    font-size: 18px;
                    font-weight: 600;
                    color: #000000;
                }
            }

            .modal-body {
                .body {
                    gap: 3rem;
                    display: flex;
                    padding: 0 2rem;
                    flex-direction: column;

                    .image {
                        text-align: center;

                        img {
                            width: 10rem;
                            height: auto;
                        }
                    }

                    p {
                        margin: 0;
                        font-size: 13px;
                        font-weight: 500;
                        color: #878787;
                        line-height: 25px;

                        span {
                            display: block;
                            font-weight: 600;
                            font-size: 17px;
                            color: #1E1E1E;
                        }
                    }

                    .buttonContainer {
                        gap: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        button {
                            font-size: 15px;
                            font-weight: 700;
                            line-height: 18px;
                            padding: 10px 15px;
                            border-radius: 10px;

                            &.approved {
                                color: #FFFFFF;
                                background: #03A900;
                                border-color: #03A900;
                            }

                            &.decline {
                                color: #1E1E1E;
                                background: #CBCBCB;
                                border-color: #CBCBCB;
                            }
                        }
                    }
                }
            }
        }
    }
}