.contentContainer {
    flex: 1;
    gap: 2rem;
    width: 100%;
    padding: 2rem;
    display: flex;
    max-width: 80%;
    margin: 5rem auto 0;
    border-radius: 10px;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;

    .balanceContainer {
        display: block;
        height: 15rem;
        border-radius: 10px;
        background-size: cover;
        padding: 2rem;
        display: flex;
        align-items: center;

        p {
            color: #fff;
            font-size: 1.5rem;
            line-height: 2;

            span {
                display: block;
                font-size: 1rem;
            }
        }
    }

    button.payoutRequest {
        border: #D1002A;
        margin: 1rem 0 0;
        font-size: 0.8rem;
        border-radius: 5px;
        background: #D1002A 0% 0% no-repeat padding-box;
    }

    .recentTransaction {
        h3 {
            font-size: 0.8rem;
            font-weight: 600;
        }

        .transactionList {
            margin-top: 1rem;

            ul {
                margin: 0;
                padding: 0;

                li.list {
                    margin: 0;
                    display: flex;
                    padding: 10px 0;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: solid 2px rgb(253, 250, 249);

                    .leftContainer {
                        display: flex;
                        align-items: center;
                        gap: 1rem;

                        img {
                            width: 3.5rem;
                            height: 2rem;
                            border-radius: 8px;
                            object-fit: cover;
                        }

                        p {
                            margin: 0;
                            padding: 0;
                            font-weight: 600;
                            font-size: 0.8rem;

                            span {
                                display: block;
                                font-weight: 400;
                            }
                        }
                    }

                    .rightContainer {
                        p {
                            margin: 0;
                            padding: 0;
                            font-weight: 600;
                            font-size: 0.8rem;
                            text-align: right;

                            span {
                                display: block;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
}