.inventoryTable {
    margin: 0;

    thead {
      border: none;

      tr {
        border: none;

        th {
          border: none;
          font-size: 15px;
          font-weight: 500;
          color: #878787;
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;

        td {
          font-size: 15px;
          font-weight: 600;
          color: #1E1E1E;
          vertical-align: middle;

          span {
            color: #fff;
            display: block;
            padding: 5px 14px;
            width: max-content;
            background: blue;
            text-align: center;
            border-radius: 18px;

            &.IN {
              background: #4462FF;
            }

            &.OUT {
              background: #FF8181;
            }

            &.Available {
              color: #03A900;
              border: 1px solid #03A900;
              background: rgba(3, 169, 0, 0.21);
            }

            &.NotAvailable {
              color: #FF0000;
              border: 1px solid #FF0000;
              background: rgba(255, 142, 142, 0.35);
            }
          }
        }
      }
    }

    tfoot {
      border-bottom: none;

      tr {
        border-bottom: none;

        td {
          padding-top: 1rem;
          border-bottom: none;
        }
      }
    }
  }