.processItem {
  gap: 10px;
  display: flex;
  flex: 0 0 9.5rem;
  font-size: 13px;
  font-weight: 500;
  color: #FFFFFF;
  border-radius: 5px;
  align-items: center;
  background: #071E99;
}

.selectContainer {
  gap: 10px;
  display: flex;
  position: relative;
  align-items: center;

  svg {
    width: 33px;
    height: 33px;
    border-radius: 5px;
    background: #ECECEC;
  }

  .select {
    width: 8rem;
    cursor: pointer;
    appearance: none;
    color: #000000;
    box-shadow: none;
    border-radius: 5px;
    background: #ECECEC;
    font-size: 13px;
    font-weight: 500;
    color: #878787;

    &:active,
    &:focus {
      box-shadow: none;
      border: none;
    }
  }

  &::after {
    width: 9px;
    top: 0;
    bottom: 0;
    content: '';
    height: 6px;
    right: 15px;
    margin: auto;
    position: absolute;
    background-size: cover;
    background: url('../../../assets/images/arrowdown.svg');
  }
}

.searchBar {
  position: relative;

  svg {
    top: 0;
    left: 10px;
    bottom: 0;
    margin: auto;
    position: absolute;
  }

  input {
    height: 33px;
    font-size: 10px;
    color: #878787;
    padding-left: 2rem;
    font-family: 'Poppins';
  }
}