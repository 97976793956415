.loginContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .loginForm {
    gap: 1rem;
    height: 100%;
    margin: auto;
    flex: 0 0 35%;
    display: flex;
    padding: 2rem 3rem;
    border-radius: 1rem;
    flex-direction: column;
    justify-content: center;

    img {
      width: 10rem;
      margin: 0 auto;
    }

    label {
      font-size: 15px;
      font-weight: 500;
      line-height: 22px;
    }

    .loginHeader {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h6 {
        color: #1E1E1E;
        font-weight: 500;
        font-size: 1.5rem;
      }

      p {
        font-size: 40px;
        color: #022BFF;
        font-style: normal;
      }
    }

    .formContainer {
      gap: 1rem;
      width: 100%;
      display: flex;
      margin: 0 auto;
      flex-direction: column;
    }

    .formContainer.formHorizontal {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      .rememberMe {
        color: #878787;

        input {
          margin-left: -1.3em;
        }

        label {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }

    .forgotPassword {
      font-size: 13px;
      color: #D1002A;
      font-weight: 500;
      text-align: right;
      text-decoration: none;
    }

    .marginContainer {
      gap: 1rem;
      width: 100%;
      display: flex;
      margin: 1rem auto 0;
      flex-direction: column;

      button {
        width: 100%;
      }

      .submitButton {
        height: 66px;
        font-size: 15px;
        font-weight: 400;
        border-radius: 38px;
        border-color: #022BFF;
        background-color: #022BFF;
      }

      .signup {
        gap: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          margin: 0;
          padding: 0;
          font-size: 12px;
        }

        .link {
          font-size: 12px;
          color: #D1002A;
          font-weight: 500;
          text-decoration: none;
        }
      }
    }
  }

  .imageContainer {
    height: 100%;
    flex: 0 0 65%;
    text-align: right;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .copyright {
    margin: 0;
    bottom: 25px;
    color: #878787;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    position: absolute;
  }
}