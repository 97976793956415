.top-bar {
    gap: 2rem;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    .nav-link {
        gap: 10px;
        display: flex;
        font-size: 15px;
        font-weight: 500;
        color: #878787;
        position: relative;
        align-items: center;

        svg {
            fill: #878787;
        }

        &.active {
            color: #022BFF;

            svg {
                fill: #022BFF;
            }

            &::after {
                content: '';
                width: 100%;
                height: 6px;
                bottom: -38px;
                position: absolute;
                background-color: #022BFF;
            }
        }
    }
}

.background-gray {
    background-color: #F1F1F1;

    .dashboard-account {
        display: flex;
        padding: 1rem 0;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #DCDCDC;

        .left-container {
            gap: 10px;
            display: flex;
            align-items: center;

            .line {
                width: 0.6px;
                height: 44px;
                background-color: #1E1E1E;
            }

            p {
                margin: 0;
                font-weight: 600;
                font-size: 12.0709px;

                span {
                    display: block;
                }
            }
        }

        .right-container {
            gap: 10px;
            display: flex;
            align-items: center;

            .bell {
                cursor: pointer;
            }

            .account-settings {
                padding: 0;
                border: none;
                font-size: 15px;
                color: #1E1E1E;
                font-weight: 500;
                background-color: transparent;

                img {
                    width: 3rem;
                    height: 3rem;
                    margin-right: 5px;
                    border-radius: 50%;
                }
            }

            .selections {
                border: none;
                border-radius: 10px;
                box-shadow: 3px 5px 10px 5px rgba(0, 0, 0, 0.1);

                a {

                    &:hover,
                    &:active,
                    &:focus,
                    &.active {
                        color: #FF0000;
                        background-color: #fff;
                    }
                }
            }
        }
    }
}