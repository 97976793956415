.successModal {
    border-radius: 20px;

    .header {
        border: none;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        .title {
            width: 100%;
            color: #000;
            font-size: 25px;
            font-weight: 600;
            text-align: center;
        }
    }

    .body {
        display: flex;
        padding: 2rem;
        padding-top: 0;
        align-items: center;
        flex-direction: column;

        p {
            width: 100%;
            color: #000;
            font-size: 25px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 1.5rem;
        }

        .close {
            width: 10rem;
            font-size: 15px;
            color: #1E1E1E;
            font-weight: 700;
            border-radius: 10px;
            background: #CBCBCB;
            border-color: #CBCBCB;
        }
    }
}