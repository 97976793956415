.approver {
  padding: 1rem 0;
  background-color: #F1F1F1;
  min-height: calc(100vh - 183px);

  .columm {
    position: relative;

    .profileContainer {
      margin-top: 1rem;
      border-radius: 10px;
      background-color: #fff;

      .header {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #DCDCDC;

        .search {
          flex: 1;
          gap: 1rem;
          display: flex;
          align-items: center;

          h5 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #1E1E1E;
          }

          .searchBar {
            position: relative;

            svg {
              top: 0;
              left: 10px;
              bottom: 0;
              margin: auto;
              position: absolute;
            }

            input {
              font-size: 10px;
              color: #878787;
              padding-left: 2rem;
              font-family: 'Poppins';
            }
          }
        }

        .filter {
          flex: 1;
          gap: 1rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .addApprover {
            gap: 10px;
            display: flex;
            flex: 0 0 10rem;
            font-size: 13px;
            font-weight: 500;
            color: #FFFFFF;
            border-radius: 5px;
            align-items: center;
            background: #071E99;
          }

          .selectContainer {
            position: relative;

            .select {
              width: 8rem;
              cursor: pointer;
              appearance: none;
              color: #000000;
              box-shadow: none;
              border-radius: 5px;
              background: #ECECEC;
              font-size: 13px;
              font-weight: 500;
              color: #878787;

              &:active,
              &:focus {
                box-shadow: none;
                border: none;
              }
            }

            &::after {
              width: 9px;
              top: 0;
              bottom: 0;
              content: '';
              height: 6px;
              right: 15px;
              margin: auto;
              position: absolute;
              background-size: cover;
              background: url('../../assets/images/arrowdown.svg');
            }
          }
        }

        .selectContainer {
          position: relative;

          .select {
            width: 7rem;
            cursor: pointer;
            appearance: none;
            color: #000000;
            box-shadow: none;
            border-radius: 5px;
            background: #ECECEC;

            &:active,
            &:focus {
              box-shadow: none;
              border: none;
            }
          }

          &::after {
            width: 9px;
            top: 0;
            bottom: 0;
            content: '';
            height: 6px;
            right: 15px;
            margin: auto;
            position: absolute;
            background-size: cover;
            background: url('../../assets/images/arrowdown.svg');
          }
        }


      }

      .body {
        gap: 1.5rem;
        display: flex;
        padding: 0 2rem;

        .cardContainer {
          gap: 1.5rem;
          display: flex;
          padding: 1rem;
          flex-direction: column;


          .card {
            display: flex;
            flex: 0 0 2rem;
            max-width: 20rem;
            position: relative;
            border-radius: 10px;
            background: #F0F0F0;

            .close {
              top: 5px;
              right: 5px;
              width: 20px;
              height: 20px;
              cursor: pointer;
              position: absolute;
            }

            .drag {
              cursor: grab;
              padding: 1rem;
              display: flex;
              align-items: center;
              background: #E7E7E7;
              justify-content: center;
              border-radius: 10px 0px 0px 10px;

              &.grabbing {
                cursor: grabbing;
              }
            }

            .info {
              flex: 1;
              gap: 1rem;
              display: flex;
              flex-direction: column;
              padding: 1rem 1.5rem 1rem 1rem;

              p {
                margin: 0;
                font-size: 15px;
                color: #022BFF;
                font-weight: 500;
                letter-spacing: 0.095em;
              }

              input {
                cursor: pointer;
              }
            }
          }
        }

        .lineContainer {
          gap: 18px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          .circleContainer {
            width: 36px;
            height: 36px;
            position: relative;

            .cirle {
              position: absolute;
              width: 36px;
              height: 36px;
            }
          }

          .arrowContainer {
            width: 29px;
            height: 100px;
            position: relative;

            .arrow {
              top: -18px;
              right: -15px;
              position: absolute;
            }

            &::after {
              width: 2px;
              content: '';
              height: 5rem;
              position: absolute;
              background-color: #c7c7c7;
              right: 0;
              left: -1px;
              margin: auto;
              top: -65px;
            }

            &.short {
              &::after {
                height: 0;
              }
            }
          }
        }
      }
    }
  }
}