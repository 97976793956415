.footer {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    justify-content: center;
    background-color: #F1F1F1;

    p {
        margin: 0;
        font-size: 15px;
        font-weight: 500;
        color: #878787;
    }
}