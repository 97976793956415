.addApprover {
    top: 0;
    z-index: 9;
    opacity: 0;
    right: 1rem;
    width: 375px;
    padding: 1.5rem;
    position: absolute;
    visibility: hidden;
    border-radius: 20px;
    background: #FFFFFF;
    transition: opacity 0.3s, visibility 0.3s;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25);

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
            color: #000000;

        }

        .close {
            width: 13px;
            height: 13px;
            cursor: pointer;
        }
    }

    .body {
        gap: 10px;
        display: flex;
        margin-top: 1rem;
        flex-direction: column;

        .group {
            label {
                font-size: 10px;
                font-weight: 400;
                color: #727272;
            }

            input,
            select {
                height: 38px;
                font-size: 10px;
                font-weight: 400;
                color: #1E1E1E;
                border-radius: 19px;
                background: #F3F3F3;
                border: 0.5px solid #D0CFCF;

                &::placeholder {
                    font-size: 10px;
                    font-weight: 400;
                    color: #AFAFAF;
                }
                
                &:focus, 
                &:active {
                    box-shadow: none;
                }
            }
        }

        .addApproverBtn {
            height: 48px;
            font-size: 15px;
            font-weight: 700;
            margin-top: 1rem;
            color: #FFFFFF;
            border-radius: 10px;
            background: #071E99;
        }
    }

    &.active {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s, visibility 0.3s;
    }
}