.table-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        margin: 0;
        font-weight: 500;
    }

    .pagination {
        gap: 7px;
        margin: 0;

        .page-item {
            border-radius: 5px;

            .page-link {
                padding: 0;
                width: 2.5rem;
                display: flex;
                height: 2.5rem;
                color: #4E4E4E;
                border: none;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                border-radius: 6.87805px;
            }

            &.box {
                .page-link {
                    padding: 0;
                    width: 2.5rem;
                    display: flex;
                    height: 2.5rem;
                    color: #333;
                    border-radius: 5px;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                }
            }

            &.active {
                background-color: #022BFF;

                .page-link {
                    color: #fff;
                }
            }
        }
    }
}