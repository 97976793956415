.inventoryTable {
  margin: 0;

  thead {
    border: none;

    tr {
      border: none;

      th {
        border: none;
        font-size: 15px;
        font-weight: 500;
        color: #878787;
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 13px;
        font-weight: 600;
        color: #1E1E1E;
        vertical-align: middle;

        span {
          color: #fff;
          display: block;
          padding: 5px 14px;
          width: max-content;
          background: blue;
          text-align: center;
          border-radius: 18px;

          &.Inactive,
          &.inactive {
            font-size: 13px;
            font-weight: 500;
            color: #D7B400;
            border-radius: 18px;
            border: 1px solid #D7B400;
            background: rgba(215, 180, 0, 0.13);
          }

          &.Active,
          &.active {
            font-size: 13px;
            font-weight: 500;
            color: #03A900;
            border-radius: 18px;
            border: 1px solid #03A900;
            background: rgba(3, 169, 0, 0.21);
          }

          &.Available {
            color: #03A900;
            border: 1px solid #03A900;
            background: rgba(3, 169, 0, 0.21);
          }

          &.NotAvailable {
            color: #FF0000;
            border: 1px solid #FF0000;
            background: rgba(255, 142, 142, 0.35);
          }
        }

        .view {
          font-size: 12px;
          font-weight: 500;
          color: #FFFFFF;
          border-radius: 18px;
          background: #4462FF;
        }
      }
    }
  }

  tfoot {
    border-bottom: none;

    tr {
      border-bottom: none;

      td {
        padding-top: 1rem;
        border-bottom: none;
      }
    }
  }
}