.processTable {
    margin: 0 !important;

    thead {
        border: none;

        tr {
            border: none;

            th {
                border: none;
                font-size: 15px;
                font-weight: 500;
                color: #878787;
            }
        }
    }

    tbody {
        tr {
            td {
                font-size: 15px;
                font-weight: 600;
                color: #1E1E1E;
                vertical-align: middle;

                img {
                    width: 5rem;
                    height: 5rem;
                    object-fit: cover;
                }

                span {
                    color: #fff;
                    display: block;
                    font-size: 13px;
                    padding: 5px 14px;
                    width: max-content;
                    background: blue;
                    text-align: center;
                    border-radius: 18px;

                    &.IN {
                        background: #4462FF;
                    }

                    &.OUT {
                        background: #FF8181;
                    }

                    &.Available {
                        color: #03A900;
                        text-transform: capitalize;
                        border: 1px solid #03A900;
                        background: rgba(3, 169, 0, 0.21);
                    }

                    &.NotAvailable {
                        color: #FF0000;
                        text-transform: capitalize;
                        border: 1px solid #FF0000;
                        background: rgba(255, 142, 142, 0.35);
                    }
                }

                .buttonContainer {
                    gap: 1rem;
                    display: flex;

                    button {
                        gap: 5px;
                        padding: 0;
                        border: none;
                        width: 6rem;
                        height: 36px;
                        font-size: 12px;
                        font-weight: 500;
                        color: #FFFFFF;
                        border-radius: 18px;
                        background: #03A900;
                        justify-content: center;

                        &.deactivate {
                            background: #FF8181;
                        }

                        &.activate {
                            background: #03A900;
                        }
                    }
                }

                &.clickable {
                    cursor: pointer;
                }
            }
        }
    }

    tfoot {
        border-bottom: none;

        tr {
            border-bottom: none;

            td {
                padding-top: 1rem;
                border-bottom: none;
            }
        }
    }
}