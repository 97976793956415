.inventory {
  padding: 1rem 0;
  background-color: #F1F1F1;
  min-height: calc(100vh - 183px);

  .columm {
    position: relative;

    .profileContainer {
      margin-top: 1rem;
      border-radius: 10px;
      background-color: #fff;

      .header {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #DCDCDC;

        .search {
          flex: 1;
          gap: 1rem;
          display: flex;
          align-items: center;

          h5 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #1E1E1E;
          }

          .searchBar {
            position: relative;

            svg {
              top: 0;
              left: 10px;
              bottom: 0;
              margin: auto;
              position: absolute;
            }

            input {
              font-size: 10px;
              color: #878787;
              padding-left: 2rem;
              font-family: 'Poppins';
            }
          }
        }

        .filter {
          flex: 1;
          gap: 1rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .processItem {
            gap: 10px;
            display: flex;
            flex: 0 0 10rem;
            font-size: 13px;
            font-weight: 500;
            color: #FFFFFF;
            border-radius: 5px;
            align-items: center;
            background: #071E99;
          }

          .requestSettings {
            gap: 10px;
            display: flex;
            flex: 0 0 9rem;
            font-size: 13px;
            font-weight: 500;
            color: #FFFFFF;
            border-radius: 5px;
            align-items: center;
            background: #071E99;
          }

          .requestButton {
            font-size: 10px;
            font-weight: 500;
            color: #1E1E1E;
          }

          .selectContainer {
            position: relative;

            .select {
              width: 8rem;
              cursor: pointer;
              appearance: none;
              color: #000000;
              box-shadow: none;
              border-radius: 5px;
              background: #ECECEC;
              font-size: 13px;
              font-weight: 500;
              color: #878787;

              &:active,
              &:focus {
                box-shadow: none;
                border: none;
              }
            }

            &::after {
              width: 9px;
              top: 0;
              bottom: 0;
              content: '';
              height: 6px;
              right: 15px;
              margin: auto;
              position: absolute;
              background-size: cover;
              background: url('../../assets/images/arrowdown.svg');
            }
          }
        }

        .selectContainer {
          position: relative;

          .select {
            width: 7rem;
            cursor: pointer;
            appearance: none;
            color: #000000;
            box-shadow: none;
            border-radius: 5px;
            background: #ECECEC;

            &:active,
            &:focus {
              box-shadow: none;
              border: none;
            }
          }

          &::after {
            width: 9px;
            top: 0;
            bottom: 0;
            content: '';
            height: 6px;
            right: 15px;
            margin: auto;
            position: absolute;
            background-size: cover;
            background: url('../../assets/images/arrowdown.svg');
          }
        }


      }

      .body {
        gap: 1.5rem;
        display: flex;
        padding: 1rem;
        flex-direction: column;

        .processTable {
          margin: 0;

          thead {
            border: none;

            tr {
              border: none;

              th {
                border: none;
                font-size: 15px;
                font-weight: 500;
                color: #878787;
              }
            }
          }

          tbody {
            tr {
              cursor: pointer;

              td {
                font-size: 15px;
                font-weight: 600;
                color: #1E1E1E;
                vertical-align: middle;

                img {
                  width: 5rem;
                }

                span {
                  color: #fff;
                  display: block;
                  padding: 5px 14px;
                  width: max-content;
                  background: blue;
                  text-align: center;
                  border-radius: 18px;

                  &.goodQuantity {
                    padding: 0;
                    font-size: 15px;
                    font-weight: 600;
                    color: #03A900;
                    background-color: transparent;
                  }

                  &.damageQuantity {
                    padding: 0;
                    font-size: 15px;
                    font-weight: 600;
                    color: #FF0000;
                    background-color: transparent;
                  }

                  &.Available {
                    color: #03A900;
                    font-weight: 500;
                    font-size: 12px;
                    border: 1px solid #03A900;
                    background: rgba(3, 169, 0, 0.21);
                  }

                  &.NotAvailable {
                    font-size: 12px;
                    font-weight: 500;
                    color: #FF0000;
                    border: 1px solid #FF0000;
                    background: rgba(255, 142, 142, 0.35);
                  }
                }
              }
            }
          }

          tfoot {
            border-bottom: none;

            tr {
              border-bottom: none;

              td {
                padding-top: 1rem;
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }

  &.show {
    padding-bottom: 25rem;
  }
}