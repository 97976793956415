.global-form-input.grey-bg {
    label {
        font-weight: 500;
    }

    input.form-control {
        height: 40px;
        border-radius: 8px;
        background-color: #eee !important;
    }


    textarea.form-control {
        border-radius: 8px;
        background-color: #eee !important;
    }
}

.global-form-input {
    .left-icon {
        bottom: 0;
        margin: 0;
        left: 20px;
        width: 20px;
        height: 66px;
        position: absolute;
    }
}