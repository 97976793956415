.history {
    border-radius: 10px;
    background-color: #fff;

    .header {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #DCDCDC;

        h5 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #1E1E1E;
        }

        .selectContainer {
            position: relative;

            .select {
                width: 9rem;
                cursor: pointer;
                appearance: none;
                color: #000000;
                box-shadow: none;
                border-radius: 5px;
                background: #ECECEC;

                &:active,
                &:focus {
                    box-shadow: none;
                    border: none;
                }
            }

            &::after {
                width: 9px;
                top: 0;
                bottom: 0;
                content: '';
                height: 6px;
                right: 15px;
                margin: auto;
                position: absolute;
                background-size: cover;
                background: url('../../../assets/images/arrowdown.svg');
            }
        }


    }

    .body {
        .list {
            gap: 1rem;
            display: flex;
            padding: 1rem;
            align-items: center;
            border-bottom: 1px solid #DCDCDC;

            img {
                width: 75px;
                height: 75px;
                object-fit: cover;
            }

            .details {
                gap: 5px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                h6 {
                    margin: 0;
                    font-size: 15px;
                    font-weight: 600;
                    color: #1E1E1E;
                }

                .type {
                    gap: 7px;
                    margin: 0;
                    display: flex;
                    font-size: 15px;
                    font-weight: 500;
                    color: #878787;
                    align-items: center;

                    .arrow {
                        width: 9px;
                        height: 12px;
                    }
                }

                .datetime {
                    margin: 0;
                    gap: 1rem;
                    display: flex;
                    font-size: 15px;
                    font-weight: 500;
                    color: #878787;
                    align-items: center;

                    span {
                        .calendar {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
        }

        .list.viewmore {
            justify-content: flex-end;

            a {
                font-size: 15px;
                font-weight: 500;
                color: #022BFF;
                text-decoration: none;
                margin: 0;
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }
    }
}