.inventory {
  padding: 1rem 0;
  background-color: #F1F1F1;
  min-height: calc(100vh - 183px);

  .columm {
    position: relative;

    .profileContainer {
      margin-top: 1rem;
      border-radius: 10px;
      background-color: #fff;

      .header {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #DCDCDC;

        .search {
          flex: 1;
          gap: 1rem;
          display: flex;
          align-items: center;

          h5 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #1E1E1E;
          }

          .buttonContainer {
            button {
              padding: 0;
              border: none;
              font-size: 20px;
              font-weight: 600;
              color: #878787;
              position: relative;
              background-color: transparent;

              &.active {
                color: #022BFF;

                svg {
                  fill: #022BFF;
                }

                &::after {
                  content: '';
                  left: 0;
                  right: 0;
                  width: 100%;
                  height: 6px;
                  bottom: -18px;
                  position: absolute;
                  background-color: #022BFF;
                }
              }
            }
          }
        }

        .filter {
          flex: 1;
          gap: 1rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;


          .approveAll {
            gap: 10px;
            display: flex;
            flex: 0 0 9rem;
            font-size: 13px;
            font-weight: 500;
            color: #FFFFFF;
            border-radius: 5px;
            align-items: center;
            background: #03A900;
            border-color: #03A900;
          }

          .selectContainer {
            gap: 1rem;
            display: flex;
            position: relative;
            align-content: center;

            .select {
              width: 8rem;
              cursor: pointer;
              appearance: none;
              color: #000000;
              box-shadow: none;
              border-radius: 5px;
              background: #ECECEC;
              font-size: 13px;
              font-weight: 500;
              color: #878787;

              &:active,
              &:focus {
                box-shadow: none;
                border: none;
              }
            }

            .searchBar {
              position: relative;

              svg {
                top: 0;
                left: 10px;
                bottom: 0;
                margin: auto;
                position: absolute;
              }

              input {
                height: 33px;
                font-size: 10px;
                color: #878787;
                padding-left: 2rem;
                font-family: 'Poppins';
              }
            }

            &::after {
              width: 9px;
              top: 0;
              bottom: 0;
              content: '';
              height: 6px;
              right: 15px;
              margin: auto;
              position: absolute;
              background-size: cover;
              background: url('../../assets/images/arrowdown.svg');
            }
          }
        }

        .selectContainer {
          position: relative;

          .select {
            width: 7rem;
            cursor: pointer;
            appearance: none;
            color: #000000;
            box-shadow: none;
            border-radius: 5px;
            background: #ECECEC;

            &:active,
            &:focus {
              box-shadow: none;
              border: none;
            }
          }

          &::after {
            width: 9px;
            top: 0;
            bottom: 0;
            content: '';
            height: 6px;
            right: 15px;
            margin: auto;
            position: absolute;
            background-size: cover;
            background: url('../../assets/images/arrowdown.svg');
          }
        }


      }

      .body {
        gap: 1.5rem;
        display: flex;
        padding: 1rem;
        flex-direction: column;

        .processTable {
          margin: 0;

          thead {
            border: none;

            tr {
              border: none;

              th {
                border: none;
                font-size: 15px;
                font-weight: 500;
                color: #878787;
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: 15px;
                font-weight: 600;
                color: #1E1E1E;
                vertical-align: middle;

                img {
                  width: 5rem;
                }

                button.button {
                  font-size: 12px;
                  font-weight: 500;
                  color: #FFFFFF;
                  border-radius: 18px;
                  background: #4462FF;
                  border-color: #4462FF;
                }

                span {
                  color: #fff;
                  display: block;
                  font-size: 13px;
                  font-weight: 500;
                  padding: 5px 14px;
                  width: max-content;
                  background: blue;
                  text-align: center;
                  border-radius: 18px;

                  &.Pending {
                    color: #D7B400;
                    border: 1px solid #D7B400;
                    background: rgba(215, 180, 0, 0.13);
                  }

                  &.InProgress {
                    color: #002bc8;
                    border: 1px solid #002bc8;
                    background: #9b9aff;
                  }

                  &.Declined {
                    color: #e50f0f;
                    border: 1px solid #e50f0f;
                    background: #d74a4a21;
                  }

                  &.Completed {
                    color: #03A900;
                    border: 1px solid #03A900;
                    background: #3fe95821;
                  }
                }
              }
            }
          }

          tfoot {
            border-bottom: none;

            tr {
              border-bottom: none;

              td {
                padding-top: 1rem;
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }

  &.show {
    padding-bottom: 25rem;
  }
}