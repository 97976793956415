.confirmAction {
    .modal-dialog {
        .modal-content {
            border-radius: 20px;
            background: #FFFFFF;
            box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25);

            .modal-header {
                border: none;
                padding: 1.5rem 1.5rem 0 1.5rem;

                .modal-title {
                    font-size: 18px;
                    font-weight: 600;
                    color: #000000;
                }
            }

            .modal-body {
                .body {
                    gap: 1rem;
                    display: flex;
                    padding: 1.5rem;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    .group {
                        margin: auto;
                        max-width: 80%;

                        label {
                            font-weight: 500;
                            font-size: 12px;
                            color: #1E1E1E;
                        }

                        input {
                            font-size: 13px;
                            font-weight: 500;
                            color: #1E1E1E;
                            border-radius: 19px;
                            background: #F3F3F3;
                            border: 0.5px solid #D0CFCF;

                            &:focus,
                            &:active {
                                box-shadow: none;
                            }
                        }
                    }

                    .buttonContainer {
                        gap: 1rem;
                        display: flex;
                        align-items: center;

                        button {
                            flex: 1;
                            width: 100%;
                            height: 48px;
                            font-size: 15px;
                            font-weight: 700;
                            color: #FFFFFF;
                            border-radius: 10px;
                            background: #071E99;

                            &.cancel {
                                background-color: grey;
                                border-color: grey;
                            }
                        }
                    }
                }
            }
        }
    }
}