.transaction {
  padding: 1rem 0;
  background-color: #F1F1F1;
  min-height: calc(100vh - 183px);

  .columm {
    position: relative;

    .profileContainer {
      margin-top: 1rem;
      border-radius: 10px;
      background-color: #fff;

      .header {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #DCDCDC;

        .search {
          gap: 1rem;
          display: flex;
          align-items: center;

          .buttonContainer {
            button {
              padding: 0;
              border: none;
              font-size: 20px;
              font-weight: 600;
              color: #878787;
              position: relative;
              background-color: transparent;

              &.active {
                color: #022BFF;

                svg {
                  fill: #022BFF;
                }

                &::after {
                  content: '';
                  left: 0;
                  right: 0;
                  width: 100%;
                  height: 6px;
                  bottom: -18px;
                  position: absolute;
                  background-color: #022BFF;
                }
              }
            }
          }

          .searchBar {
            position: relative;

            svg {
              top: 0;
              left: 10px;
              bottom: 0;
              margin: auto;
              position: absolute;
            }

            input {
              font-size: 10px;
              color: #878787;
              padding-left: 2rem;
              font-family: 'Poppins';
            }
          }
        }

        .filter {
          gap: 1rem;
          display: flex;
          align-items: center;

          .from,
          .to {
            gap: 1rem;
            display: flex;
            font-size: 13px;
            font-weight: 500;
            color: #1E1E1E;
            font-style: normal;
            align-items: center;

            p {
              gap: 10px;
              margin: 0;
              display: flex;
              align-items: center;

              svg {
                width: 36px;
                height: 36px;
                border-radius: 5px;
                background: #ECECEC;
              }
            }

            input {
              height: 36px;
              font-size: 10px;
              color: #878787;
              font-weight: 500;
              border-radius: 5px;
              background: #ECECEC;
            }
          }

          .tableActions {
            gap: 10px;
            display: flex;
            align-items: center;

            button {
              gap: 5px;
              height: 36px;
              border: none;
              display: flex;
              line-height: 1;
              font-size: 13px;
              font-weight: 500;
              color: #FFFFFF;
              border-radius: 5px;
              align-items: center;
              background: #4462FF;

              &.download {
                background-color: #03A900;
              }
            }
          }
        }

        .selectContainer {
          position: relative;

          .select {
            width: 7rem;
            cursor: pointer;
            appearance: none;
            color: #000000;
            box-shadow: none;
            border-radius: 5px;
            background: #ECECEC;

            &:active,
            &:focus {
              box-shadow: none;
              border: none;
            }
          }

          &::after {
            width: 9px;
            top: 0;
            bottom: 0;
            content: '';
            height: 6px;
            right: 15px;
            margin: auto;
            position: absolute;
            background-size: cover;
            background: url('../../assets/images/arrowdown.svg');
          }
        }
      }

      .body {
        gap: 1.5rem;
        display: flex;
        padding: 1rem;
        flex-direction: column;
      }
    }
  }
}