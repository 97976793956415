.userRoles {
  margin: 0;

  thead {
    border: none;

    tr {
      border: none;

      th {
        border: none;
        font-size: 15px;
        font-weight: 500;
        color: #878787;
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 13px;
        font-weight: 600;
        color: #1E1E1E;
        vertical-align: middle;

        .tableActions {
          gap: 2rem;
          display: flex;
          justify-content: flex-start;

          button {
            border: none;
            font-size: 13px;
            color: #FFFFFF;
            font-weight: 500;
            border-radius: 18px;

            &.update {
              background: #03A900;
            }

            &.assignPermission {
              background: #4462FF;
            }

            &.delete {
              background: #FF8E8E;
            }

            &:focus,
            &:active {
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  tfoot {
    border-bottom: none;

    tr {
      border-bottom: none;

      td {
        padding-top: 1rem;
        border-bottom: none;
      }
    }
  }
}