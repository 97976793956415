.assignPermission {
    .modal-dialog {
        .modal-content {
            border-radius: 20px;
            background: #FFFFFF;
            box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25);

            .modal-header {
                border: none;
                padding: 1.5rem 1.5rem 0 1.5rem;

                .modal-title {
                    font-size: 18px;
                    font-weight: 600;
                    color: #000000;
                }
            }

            .modal-body {
                padding: 0;

                .body {
                    padding: 0;
                    gap: 1rem;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    .header {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-bottom: 1px solid #ECECEC;

                        p {
                            font-weight: 500;
                            font-size: 13px;
                            color: #878787;
                            text-align: center;

                            span {
                                display: block;
                                font-size: 20px;
                                font-weight: 600;
                                color: #1E1E1E;

                            }
                        }
                    }

                    .group {
                        margin: auto;
                        max-width: 80%;

                        label {
                            font-weight: 500;
                            font-size: 12px;
                            color: #1E1E1E;
                        }

                        input {
                            font-size: 13px;
                            font-weight: 500;
                            color: #1E1E1E;
                            border-radius: 19px;
                            background: #F3F3F3;
                            border: 0.5px solid #D0CFCF;

                            &:focus,
                            &:active {
                                box-shadow: none;
                            }
                        }
                    }

                    .permissions {
                        width: 100%;
                        padding: 0 2rem 1rem;

                        label {
                            font-weight: 500;
                            font-size: 12px;
                            color: #1E1E1E;
                        }

                        .list-data {
                            display: flex;
                            justify-content: space-between;

                            p {
                                font-size: 17px;
                                font-weight: 600;
                                color: #1E1E1E;
                            }

                            input {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}