.addItem {
    top: 0;
    z-index: 9;
    opacity: 0;
    right: 1rem;
    width: 375px;
    height: 10rem;
    padding: 1.5rem;
    overflow-y: hidden;
    position: absolute;
    visibility: hidden;
    border-radius: 20px;
    background: #FFFFFF;
    transition: height 0.3s opacity 0.3s, visibility 0.3s;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25);

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
            color: #000000;

        }

        .close {
            width: 13px;
            height: 13px;
            cursor: pointer;
        }
    }

    .body {
        gap: 10px;
        display: flex;
        margin-top: 1rem;
        flex-direction: column;

        .searchBar {
            .search {
                font-size: 10px;
                font-weight: 400;
                color: #727272;
            }

            .selectItem {
                height: 38px;
                font-size: 10px;
                font-weight: 400;
                color: #1E1E1E;
                border-radius: 19px;
                background: #F3F3F3;
                border: 0.5px solid #D0CFCF;

                &::placeholder {
                    font-size: 10px;
                    font-weight: 400;
                    color: #AFAFAF;
                }

                &:focus,
                &:active {
                    box-shadow: none;
                }
            }
        }

        .group {
            label {
                font-size: 10px;
                font-weight: 400;
                color: #727272;
            }

            input,
            select {
                height: 38px;
                font-size: 10px;
                font-weight: 400;
                color: #1E1E1E;
                border-radius: 19px;
                background: #F3F3F3;
                border: 0.5px solid #D0CFCF;

                &::placeholder {
                    font-size: 10px;
                    font-weight: 400;
                    color: #AFAFAF;
                }

                &:focus,
                &:active {
                    box-shadow: none;
                }
            }

            textarea {
                font-size: 10px;
                font-weight: 400;
                color: #1E1E1E;
                border-radius: 19px;
                background: #F3F3F3;
                border: 0.5px solid #D0CFCF;

                &::placeholder {
                    font-size: 10px;
                    font-weight: 400;
                    color: #AFAFAF;
                }

                &:focus,
                &:active {
                    box-shadow: none;
                }
            }

            .inline {
                gap: 1rem;
                display: flex;
                align-items: center;

                .small {
                    width: 5rem;
                    text-align: center;
                }

                .actions {
                    gap: 5px;
                    display: flex;
                    align-items: center;

                    svg {
                        width: 36px;
                        height: 36px;
                        cursor: pointer;
                    }
                }

                .stock {
                    height: 38px;
                    display: flex;
                    align-items: flex-start;

                    p {
                        margin: 0;
                        padding: 0;
                        font-size: 10px;
                        color: #727272;
                        font-weight: 400;
                    }
                }
            }

            .buttons {
                gap: 1rem;
                display: flex;
                align-items: center;

                button {
                    width: 93px;
                    border: none;
                    height: 36px;
                    font-size: 13px;
                    font-weight: 500;
                    color: #878787;
                    padding: 8px 33px;
                    border-radius: 18px;
                    background: #F3F3F3;
                    border: 0.5px solid #D0CFCF;

                    &:active,
                    &:focus {
                        box-shadow: none;
                        border: 0.5px solid #D0CFCF;
                    }
                }

                button.active {
                    color: #fff;
                    background-color: #071E99;
                }
            }

            .transaction {
                appearance: none;
                padding-right: 20px;
                -moz-appearance: none;
                background-size: 10px;
                -webkit-appearance: none;
                background-repeat: no-repeat;
                background-position: 97% center;
                background-image: url("../../../../../assets/svg/caret down.svg");
            }
        }

        .quantities {
            display: flex;
            justify-content: space-between;

            .good {
                label {
                    color: #03A900;
                }
            }

            .damaged {
                label {
                    color: #FF0000;
                }
            }
        }

        .processButton {
            height: 48px;
            font-size: 15px;
            font-weight: 700;
            margin-top: 1rem;
            color: #FFFFFF;
            border-radius: 10px;
            background: #071E99;
        }
    }

    &.active {
        opacity: 1;
        height: auto;
        visibility: visible;
        transition: height 0.3s opacity 0.3s, visibility 0.3s;
    }
}