.inventoryModal {
    .modal-dialog {
        .modal-content {
            border-radius: 20px;
            background: #FFFFFF;
            box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25);

            .modal-header {
                border: none;

                .modal-title {
                    font-size: 18px;
                    font-weight: 600;
                    color: #000000;
                }
            }

            .modal-body {
                .body {
                    gap: 1rem;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    .image {
                        width: 12rem;

                        img {
                            width: 100%;
                        }
                    }

                    .group {
                        p {
                            font-size: 13px;
                            font-weight: 500;
                            color: #878787;
                            line-height: 25px;

                            span {
                                display: block;
                                font-size: 17px;
                                font-weight: 600;
                                color: #1E1E1E;

                                &.Completed {
                                    font-size: 10px;
                                    font-weight: 500;
                                    color: #03A900;
                                    padding: 5px 20px;
                                    width: max-content;
                                    border-radius: 18px;
                                    border: 1px solid #03A900;
                                    background: rgba(3, 169, 0, 0.21);
                                }


                                &.Pending {
                                    font-size: 13px;
                                    font-weight: 500;
                                    color: #D7B400;
                                    padding: 5px 20px;
                                    width: max-content;
                                    border-radius: 18px;
                                    border: 1px solid #D7B400;
                                    background: rgba(215, 180, 0, 0.13);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}