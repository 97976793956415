.password {
  padding: 1rem 0;
  background-color: #F1F1F1;
  min-height: calc(100vh - 183px);

  .back {
    gap: 10px;
    width: 13rem;
    display: flex;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 7px 20px;
    border-radius: 5px;
    align-items: center;
    background: #878787;
    text-decoration: none;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .profileContainer {
    max-width: 800px;
    margin-top: 1rem;
    border-radius: 10px;
    background-color: #fff;

    .header {
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #DCDCDC;

      h5 {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        color: #1E1E1E;
      }

      .selectContainer {
        position: relative;

        .select {
          width: 7rem;
          cursor: pointer;
          appearance: none;
          color: #000000;
          box-shadow: none;
          border-radius: 5px;
          background: #ECECEC;

          &:active,
          &:focus {
            box-shadow: none;
            border: none;
          }
        }

        &::after {
          width: 9px;
          top: 0;
          bottom: 0;
          content: '';
          height: 6px;
          right: 15px;
          margin: auto;
          position: absolute;
          background-size: cover;
          background: url('../../assets/images/arrowdown.svg');
        }
      }


    }

    .body {
      gap: 1.5rem;
      display: flex;
      padding: 1rem;
      flex-direction: column;

      .forms {
        gap: 1rem;
        display: flex;
        justify-content: space-between;

        .group {
          flex: 1;

          label {
            font-size: 15px;
            font-weight: 500;
            color: #727272;
          }

          input {
            height: 38px;
            font-weight: 400;
            font-size: 13px;
            color: #AFAFAF;
            border-radius: 10px;
            background: #F3F3F3;
            border: 0.5px solid #D0CFCF;

            &:active,
            &:focus {
              box-shadow: none;
              border: none;
            }
          }
        }
      }

      button {
        width: 15rem;
        height: 38px;
        font-size: 13px;
        color: #FFFFFF;
        font-weight: 500;
        border-radius: 10px;
        background: #071E99;
      }
    }
  }
}