.deleteRole {
    .modal-dialog {
        .modal-content {
            border-radius: 20px;
            background: #FFFFFF;
            box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25);

            .modal-header {
                border: none;
                padding: 1.5rem 1.5rem 0 1.5rem;

                .modal-title {
                    font-size: 18px;
                    font-weight: 600;
                    color: #000000;
                }
            }

            .modal-body {
                .body {
                    padding: 1.5rem;
                    gap: 1rem;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    .buttons {
                        gap: 1rem;
                        display: flex;
                        margin-top: 1rem;
                        justify-content: center;

                        button {
                            border: none;
                            flex: 0 0 8rem;
                            border-radius: 10px;

                            &.yes {
                                font-size: 15px;
                                font-weight: 700;
                                color: #FFFFFF;
                                background: #03A900;
                            }

                            &.decline {
                                font-size: 15px;
                                font-weight: 700;
                                color: #1E1E1E;
                                background: #CBCBCB;
                            }

                            &:active,
                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }
    }
}